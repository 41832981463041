import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useUser } from './context/UserContext';
import axios from './api';  // Your Axios instance
import Cookies from 'js-cookie';

export default function Navbar() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const { user } = useUser();
    const dropdownRef = useRef(null);
    const location = useLocation();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    // Function to check if a nav item is active
    const isActive = (path) => location.pathname === path;

    // Get profile URL from environment variable
    const profileUrl = `${process.env.REACT_APP_ACCOUNTS_HOST}/user/profile`;
    const logoutUrl = `${process.env.REACT_APP_ACCOUNTS_HOST}/logout`;

    // Logout function
    const handleLogout = async () => {
        try {
            // Get the XSRF token from the cookie
            const xsrfToken = Cookies.get('XSRF-TOKEN');

            // Perform the POST request to logout
            await axios.post(logoutUrl, {}, {
                headers: {
                    'x-xsrf-token': xsrfToken,  // Add the X-XSRF-TOKEN header
                },
                withCredentials: true,  // Ensure cookies are sent with the request
            });

            // After successful logout, redirect to login or homepage
            window.location.href = `${process.env.REACT_APP_ACCOUNTS_HOST}/login`;
        } catch (error) {
            console.error('Error during logout:', error);
            // Handle any error during logout (e.g., show a message or redirect)
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        if (isDropdownOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isDropdownOpen]);

    return (
        <nav className="bg-white border-b border-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    {/* Left side (Logo and Links) */}
                    <div className="flex">
                        <div className="shrink-0 flex items-center">
                            <Link
                                to="/">
                                <svg
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="block h-9 w-auto"
                                >
                                    <path
                                        d="M36.5624 0.499756H8.43751C3.7776 0.499756 0 4.27736 0 8.93727V37.0624C0 41.7224 3.7776 45.5 8.43751 45.5H36.5624C41.2223 45.5 44.9999 41.7224 44.9999 37.0624V8.93727C44.9999 4.27736 41.2223 0.499756 36.5624 0.499756Z"
                                        fill="#141414"
                                    ></path>
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M16.875 11.2795C16.875 10.0858 17.9757 9.19605 19.1429 9.44614L29.0809 11.5756C30.6665 11.9154 31.1024 13.9715 29.7908 14.9253L24.1022 19.0624C23.6607 19.3834 23.1013 19.4961 22.57 19.3711L12.3315 16.9622C12.258 16.9449 12.1875 17.0007 12.1875 17.0763V29.9086C12.1875 29.963 12.2249 30.0102 12.2778 30.0227L26.106 33.2762C26.1795 33.2935 26.25 33.2377 26.25 33.1621V23.713C26.25 23.1229 26.5279 22.5672 27 22.2131L31.6875 18.6976C32.9235 17.7706 34.6875 18.6524 34.6875 20.1976V29.4532C34.6875 30.6925 33.5066 31.5908 32.3123 31.2602L29.2107 30.3985C29.0078 30.3421 28.8674 30.1574 28.8674 29.9468V28.9743C28.8674 28.6643 29.1629 28.4397 29.4616 28.5226L32.6639 29.4119C32.7386 29.4326 32.8125 29.3765 32.8125 29.299V20.1976L28.125 23.7131V33.3102C28.125 34.5193 26.9975 35.4122 25.8206 35.1353L11.7581 31.8266C10.9111 31.6273 10.3125 30.8716 10.3125 30.0015V16.9283C10.3125 15.7192 11.44 14.8263 12.6169 15.1032L22.9471 17.5336C22.9803 17.5415 23.0152 17.5344 23.0428 17.5143L28.4757 13.5634C28.5576 13.5038 28.5304 13.3752 28.4313 13.354L18.8918 11.3099C18.8188 11.2943 18.75 11.3499 18.75 11.4245V15.3544C18.75 15.5069 18.6067 15.6188 18.4588 15.5818L17.2301 15.2746C17.0214 15.2224 16.875 15.035 16.875 14.8199V11.2795Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M18.2777 19.7846C18.4864 19.8368 18.6328 20.0243 18.6328 20.2394V26.0136L23.4783 27.2818C23.6846 27.3358 23.8284 27.5221 23.8284 27.7353V28.6248C23.8284 28.929 23.5431 29.1527 23.2476 29.08L17.471 27.6585C17.0522 27.5553 16.7578 27.1796 16.7578 26.7482V19.7798C16.7578 19.5892 16.9369 19.4494 17.1218 19.4956L18.2777 19.7846Z"
                                        fill="white"
                                    ></path>
                                </svg>

                            </Link>
                        </div>

                        {/* Nav Links */}
                        <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                            <Link
                                to="/"
                                className={`inline-flex items-center px-1 pt-1 text-sm leading-5 text-gray-900 focus:outline-none transition duration-150 ease-in-out ${isActive('/') ? 'border-b-2 border-indigo-400' : ''
                                    }`}
                            >
                                Search
                            </Link>
                            <Link
                                to="/lists"
                                className={`inline-flex items-center px-1 pt-1 text-sm leading-5 text-gray-900 focus:outline-none transition duration-150 ease-in-out ${isActive('/lists') ? 'border-b-2 border-indigo-400' : ''
                                    }`}
                            >
                                Lists
                            </Link>
                            <Link
                                to="/api"
                                className={`inline-flex items-center px-1 pt-1 text-sm leading-5 text-gray-900 focus:outline-none transition duration-150 ease-in-out ${isActive('/api') ? 'border-b-2 border-indigo-400' : ''
                                    }`}
                            >
                                API
                            </Link>
                            <Link
                                to="/settings"
                                className={`inline-flex items-center px-1 pt-1 text-sm leading-5 text-gray-900 focus:outline-none transition duration-150 ease-in-out ${isActive('/settings') ? 'border-b-2 border-indigo-400' : ''
                                    }`}
                            >
                                Settings
                            </Link>
                        </div>
                    </div>

                    {/* Right side (Profile and Dropdown) */}
                    {user && (
                        <div className="hidden sm:flex sm:items-center sm:ml-6">
                            <div className="ml-3 relative" ref={dropdownRef}>
                                <button
                                    className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition"
                                    aria-expanded={isDropdownOpen}
                                    onClick={toggleDropdown}
                                >
                                    <img
                                        className="h-8 w-8 rounded-full object-cover"
                                        src={user.profile_photo_url}
                                        alt={user.name}
                                    />
                                </button>

                                {isDropdownOpen && (
                                    <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-50">
                                        <div className="block px-4 py-2 text-xs text-gray-400">Manage Account</div>
                                        <a href={profileUrl} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            Profile
                                        </a>
                                        <div className="border-t border-gray-200"></div>
                                        <button
                                            onClick={handleLogout}  // Call handleLogout on click
                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            Log Out
                                        </button>
                                    </div>
                                )}
                            </div>

                        </div>
                    )}

                    {/* Mobile menu button */}
                    <div className="-mr-2 flex items-center sm:hidden">
                        <button
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                            aria-expanded={isMobileMenuOpen}
                            onClick={toggleMobileMenu}
                        >
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                {isMobileMenuOpen ? (
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                ) : (
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                )}
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className="sm:hidden">
                    <div className="pt-2 pb-3 space-y-1">
                        <Link
                            to="/"
                            className={`block pl-3 pr-4 py-2 text-base font-medium focus:outline-none transition duration-150 ease-in-out ${isActive('/') ? 'text-indigo-700 bg-indigo-50 focus:text-indigo-800 focus:bg-indigo-100' : 'text-gray-700'
                                }`}
                        >
                            Search
                        </Link>
                        <Link
                            to="/lists"
                            className={`block pl-3 pr-4 py-2 text-base font-medium focus:outline-none transition duration-150 ease-in-out ${isActive('/lists') ? 'text-indigo-700 bg-indigo-50 focus:text-indigo-800 focus:bg-indigo-100' : 'text-gray-700'
                                }`}
                        >
                            Lists
                        </Link>
                        <Link
                            to="/api"
                            className={`block pl-3 pr-4 py-2 text-base font-medium focus:outline-none transition duration-150 ease-in-out ${isActive('/api') ? 'text-indigo-700 bg-indigo-50 focus:text-indigo-800 focus:bg-indigo-100' : 'text-gray-700'
                                }`}
                        >
                            API
                        </Link>
                        <Link
                            to="/settings"
                            className={`block pl-3 pr-4 py-2 text-base font-medium focus:outline-none transition duration-150 ease-in-out ${isActive('/settings') ? 'text-indigo-700 bg-indigo-50 focus:text-indigo-800 focus:bg-indigo-100' : 'text-gray-700'
                                }`}
                        >
                            Settings
                        </Link>
                    </div>
                    <div className="pt-4 pb-1 border-t border-gray-200">
                        {user && (
                            <div className="flex items-center px-4">
                                <div className="shrink-0 mr-3">
                                    <img className="h-10 w-10 rounded-full object-cover" src={user.profile_photo_url} alt={user.name} />
                                </div>
                                <div>
                                    <div className="font-medium text-base text-gray-800">{user.name}</div>
                                    <div className="font-medium text-sm text-gray-500">{user.email}</div>
                                </div>
                            </div>
                        )}
                        <div className="mt-3 space-y-1">
                            <a href={profileUrl} className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out">
                                Profile
                            </a>

                            <form method="POST" action="/logout">
                                <button type="submit" className="block w-full text-left pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out">
                                    Log Out
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
}
