import React from 'react';
import Navbar from './Navbar';

export default function Layout({ title, children }) {
    return (
        <div className="min-h-screen bg-gray-100">
            {/* Navbar */}
            <Navbar />

            {/* Main Content Section */}
            <main className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white overflow-hidden shadow-xl sm:rounded-lg">
                        <div className="p-6 lg:p-8 bg-white border-b border-gray-200">
                            {children} {/* This renders the page content */}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
