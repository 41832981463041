import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Search from './Pages/Search';
import Lists from './Pages/Lists';
import API from './Pages/API';
import Settings from './Pages/Settings';
import AuthMiddleware from './middlewares/AuthMiddleware';
import { UserProvider } from './context/UserContext';

function App() {
  return (
    <UserProvider>
      <Router>
        <AuthMiddleware>
          <Layout>
            <Routes>
              <Route path="/" element={<Search />} />
              <Route path="/lists" element={<Lists />} />
              <Route path="/api" element={<API />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </Layout>
        </AuthMiddleware>
      </Router>
    </UserProvider>
  );
}

export default App;
