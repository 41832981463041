import React from 'react';

export default function Settings() {
    return (
        <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">Settings</h1>
            <p className="text-gray-600">
                Configure your account settings, preferences, and manage notifications to make the most out of your LaCleo.ai experience.
            </p>
        </div>
    );
}
