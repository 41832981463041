import React from 'react';

export default function Search() {
    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">Search</h1>
            <p>Welcome to the Search page.</p>
        </div>
    );
}
