import React from 'react';

export default function API() {
    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">API</h1>
            <p>Welcome to the API page.</p>
        </div>
    );
}
