import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from '../api';
import { useUser } from '../context/UserContext';

const AuthMiddleware = ({ children }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { setUser } = useUser();
    const hasCheckedAuth = useRef(false); // Prevent repeated checks

    useEffect(() => {
        const checkUserSession = async () => {
            if (hasCheckedAuth.current) return;
            hasCheckedAuth.current = true; // Set the flag to true after the first check

            // Dynamically get token name from environment variable
            const tokenName = process.env.REACT_APP_PR_ACCESS_TOKEN || 'LocalAccessToken';
            const token = Cookies.get(tokenName); // Fetch token dynamically

            // If no token is present, redirect to login
            if (!token) {
                window.location.href = `${process.env.REACT_APP_ACCOUNTS_HOST}/login`;
                return;
            }

            try {
                // Make API request to check user validity
                const response = await axios.get('/api/v1/user', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // If the response status is 200, continue loading the app
                if (response.status === 200) {
                    setUser(response.data.data);  // Store the user data in the context
                    setLoading(false);
                } else {
                    // Redirect to login if user is unauthorized
                    window.location.href = `${process.env.REACT_APP_ACCOUNTS_HOST}/login`;
                }
            } catch (error) {
                // Redirect to login if there's an error
                window.location.href = `${process.env.REACT_APP_ACCOUNTS_HOST}/login`;
            }
        };

        checkUserSession();
    }, [navigate, setUser]);

    // Loading state to prevent rendering the children before the auth check completes
    if (loading) {
        return <div>Loading...</div>;
    }

    // Render the children only after successful authentication
    return <>{children}</>;
};

export default AuthMiddleware;
