import React from 'react';

export default function Lists() {
    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">Lists</h1>
            <p>Welcome to the Lists page.</p>
        </div>
    );
}
